

























import Vue from 'vue';
import { GetCachedData } from '@/assets/mixins';

export default Vue.extend({
  name: 'Legal-Page',
  components: {},
  inject: [],
  mixins: [],
  props: {},
  data: () => {
    return {
      type: '' as DocType,
      lastUpdatedTimeStamp: '',
      legalData: [] as LegalDocSection[],
    };
  },
  computed: {
    title (): string {
      switch (this.type) {
        case 'toc':
          return 'TERMS AND CONDITIONS';
        case 'pp':
          return 'PRIVACY POLICY';
        case 'aup':
          return 'ACCEPTABLE USE POLICY';
        case 'cp':
          return 'COOKIES POLICY';
        default:
          this.lastUpdatedTimeStamp = new Date().toISOString();
          return 'Unknown document type';
      }
    },
  },
  created () {
    // Extract legal document type
    this.type = this.$route.params.type as DocType;

    // Get cached data
    GetCachedData(`legal/${this.type}.json`).then((response) => {
      if (response && response.status === 200) {
        const responseData: LegalDoc = response.data;
        this.lastUpdatedTimeStamp = responseData.lastUpdated;
        this.legalData = responseData.data;
      }
    }).catch((e) => {
      console.error(e);
    });
  },
  methods: {
    // Note: Improve this further at later date, the way v-html binding is done, it doesnt work properly for nested stuff
    processSegments (primary: number, segments: Array<string | SubSegment>): string {
      let outputHTML: string = '';
      for (const idx in segments) {
        if (segments[idx]) {
          // Determine if string or subsegment
          const data: string | SubSegment = segments[idx];
          const subsectionNumber: number = parseInt(idx) + 1;
          if (typeof data === 'string') {
            // Data is string render as normal
            outputHTML += `<p class="segment-row">${(primary + '.' + subsectionNumber)} ${data}</p>`;
          } else {
            // Data is of type SubSegment / Open P tag
            let subsegmentHTML: string = `<p class="segment-row">${(primary + '.' + subsectionNumber)} ${data.main}`;
            // Open ul tag
            subsegmentHTML += '<ul class="sub-segment">';
            // Add the sub points
            for (const subIdx in data.subpoints) {
              if (data.subpoints[subIdx]) {
                subsegmentHTML += `<li>• ${data.subpoints[subIdx]}</li>`;
              }
            }
            // Close ul tag
            subsegmentHTML += '</ul>';
            // Close ptag
            subsegmentHTML += '</p>';
            // Append to outputHTML
            outputHTML += subsegmentHTML;
          }
        }
      }

      return outputHTML;
    },
  },
});

type DocType = 'toc' | 'pp' | 'aup' | 'cp';

interface LegalDoc {
  lastUpdated: string;
  data: LegalDocSection[];
}

interface LegalDocSection {
  title: string;
  segments: Array<string | SubSegment>;
}

interface SubSegment {
  main: string;
  subpoints: string[];
}
